@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-white dark:bg-black-800;
  margin: 0;
  @apply font-Supreme;
}

body * {
  @apply transition-colors duration-300;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Stretch';
  src: url('../../public/fonts/stretch/StretchPro.otf');
}

.temperature__gradient {
  background: rgb(79, 115, 206);
  background: linear-gradient(
    90deg,
    rgba(79, 115, 206, 1) 0%,
    rgba(79, 115, 206, 1) 2%,
    rgba(79, 115, 206, 1) 4%,
    rgba(79, 115, 206, 1) 6%,
    rgba(79, 115, 206, 1) 8%,
    rgba(79, 115, 206, 1) 10%,
    rgba(79, 115, 206, 1) 12%,
    rgba(79, 115, 206, 1) 14%,
    rgba(79, 115, 206, 1) 16%,
    rgba(79, 115, 206, 1) 18%,
    rgba(79, 115, 206, 1) 20%,
    rgba(79, 115, 206, 1) 22%,
    rgba(70, 123, 216, 1) 24%,
    rgba(61, 132, 227, 1) 26%,
    rgba(53, 140, 237, 1) 28%,
    rgba(44, 149, 248, 1) 30%,
    rgba(47, 152, 246, 1) 32%,
    rgba(49, 155, 244, 1) 34%,
    rgba(52, 158, 243, 1) 36%,
    rgba(54, 160, 241, 1) 38%,
    rgba(59, 167, 244, 1) 40%,
    rgba(64, 174, 247, 1) 42%,
    rgba(71, 181, 245, 1) 44%,
    rgba(78, 188, 243, 1) 46%,
    rgba(85, 196, 241, 1) 48%,
    rgba(92, 203, 239, 1) 50%,
    rgba(95, 206, 230, 1) 52%,
    rgba(98, 208, 221, 1) 54%,
    rgba(101, 211, 212, 1) 56%,
    rgba(114, 211, 203, 1) 58%,
    rgba(127, 212, 194, 1) 60%,
    rgba(140, 212, 185, 1) 62%,
    rgba(157, 212, 170, 1) 64%,
    rgba(173, 211, 154, 1) 66%,
    rgba(189, 210, 138, 1) 68%,
    rgba(202, 210, 126, 1) 70%,
    rgba(214, 209, 114, 1) 72%,
    rgba(221, 208, 100, 1) 74%,
    rgba(229, 208, 86, 1) 76%,
    rgba(236, 207, 72, 1) 78%,
    rgba(233, 192, 68, 1) 80%,
    rgba(230, 177, 64, 1) 82%,
    rgba(227, 162, 60, 1) 84%,
    rgba(224, 147, 56, 1) 86%,
    rgba(221, 131, 52, 1) 88%,
    rgba(218, 116, 48, 1) 90%,
    rgba(214, 100, 44, 1) 92%,
    rgba(211, 85, 40, 1) 94%,
    rgba(207, 69, 36, 1) 96%,
    rgba(200, 38, 28, 1) 98%,
    rgba(200, 38, 28, 1) 100%
  );
}
/**
 * @license
 *
 * Font Family: Supreme
 * Designed by: Jérémie Hornus, Ilya Naumoff
 * URL: https://www.fontshare.com/fonts/supreme
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Supreme Variable(Variable font)
 * Supreme Variable Italic(Variable font)
 * Supreme Thin
 * Supreme Thin Italic
 * Supreme Extralight
 * Supreme Extralight Italic
 * Supreme Light
 * Supreme Light Italic
 * Supreme Regular
 * Supreme Italic
 * Supreme Medium
 * Supreme Medium Italic
 * Supreme Bold
 * Supreme Bold Italic
 * Supreme Extrabold         
 * Supreme Extrabold Italic
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 800.0;
*
* available axes:

* 'wght' (range from 100.0 to 800.0)

*/

@layer base {
  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-Variable.woff2')
        format('woff2'),
      url('../../public/fonts/supreme/Supreme-Variable.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-Variable.ttf') format('truetype');
    font-weight: 100 800;
    font-display: swap;
    font-style: normal;
  }

  /**
  * This is a variable font
  * You can controll variable axes as shown below:
  * font-variation-settings: 'wght' 800.0;
  *
  * available axes:

  * 'wght' (range from 100.0 to 800.0)

  */

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-VariableItalic.woff2')
        format('woff2'),
      url('../../public/fonts/supreme/Supreme-VariableItalic.woff')
        format('woff'),
      url('../../public/fonts/supreme/Supreme-VariableItalic.ttf')
        format('truetype');
    font-weight: 100 800;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-Thin.woff2') format('woff2'),
      url('../../public/fonts/supreme/Supreme-Thin.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-Thin.ttf') format('truetype');
    font-weight: 100;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-ThinItalic.woff2')
        format('woff2'),
      url('../../public/fonts/supreme/Supreme-ThinItalic.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-ThinItalic.ttf')
        format('truetype');
    font-weight: 100;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-Extralight.woff2')
        format('woff2'),
      url('../../public/fonts/supreme/Supreme-Extralight.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-Extralight.ttf')
        format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-ExtralightItalic.woff2')
        format('woff2'),
      url('../../public/fonts/supreme/Supreme-ExtralightItalic.woff')
        format('woff'),
      url('../../public/fonts/supreme/Supreme-ExtralightItalic.ttf')
        format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-Light.woff2') format('woff2'),
      url('../../public/fonts/supreme/Supreme-Light.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-LightItalic.woff2')
        format('woff2'),
      url('../../public/fonts/supreme/Supreme-LightItalic.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-LightItalic.ttf')
        format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-Regular.woff2') format('woff2'),
      url('../../public/fonts/supreme/Supreme-Regular.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-Italic.woff2') format('woff2'),
      url('../../public/fonts/supreme/Supreme-Italic.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-Medium.woff2') format('woff2'),
      url('../../public/fonts/supreme/Supreme-Medium.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-MediumItalic.woff2')
        format('woff2'),
      url('../../public/fonts/supreme/Supreme-MediumItalic.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-MediumItalic.ttf')
        format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-Bold.woff2') format('woff2'),
      url('../../public/fonts/supreme/Supreme-Bold.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-BoldItalic.woff2')
        format('woff2'),
      url('../../public/fonts/supreme/Supreme-BoldItalic.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-BoldItalic.ttf')
        format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-Extrabold.woff2')
        format('woff2'),
      url('../../public/fonts/supreme/Supreme-Extrabold.woff') format('woff'),
      url('../../public/fonts/supreme/Supreme-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Supreme';
    src: url('../../public/fonts/supreme/Supreme-ExtraboldItalic.woff2')
        format('woff2'),
      url('../../public/fonts/supreme/Supreme-ExtraboldItalic.woff')
        format('woff'),
      url('../../public/fonts/supreme/Supreme-ExtraboldItalic.ttf')
        format('truetype');
    font-weight: 800;
    font-display: swap;
    font-style: italic;
  }
}
