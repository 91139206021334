.toggle-theme {
  font-size: 15%;
  height: 16em;
  width: 30em;
}

.moon {
  top: 3em;
  left: 3em;
  transform: rotate(-75deg);
  width: 10em;
  height: 10em;
  box-shadow: 3em 2.5em 0 0em #d9fbff inset,
    rgba(255, 255, 255, 0.1) 0em -7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 3em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 2em 13em 0 -4em,
    rgba(255, 255, 255, 0.1) 6em 2em 0 -4.1em,
    rgba(255, 255, 255, 0.1) 8em 8em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 6em 13em 0 -4.5em,
    rgba(255, 255, 255, 0.1) -4em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) -1em 10em 0 -4.5em;
}

.sun {
  top: 4.5em;
  left: 18em;
  transform: rotate(0deg);
  width: 7em;
  height: 7em;
  background: #fff;
  box-shadow: 3em 3em 0 5em #fff inset, 0 -5em 0 -2.7em #fff,
    3.5em -3.5em 0 -3em #fff, 5em 0 0 -2.7em #fff, 3.5em 3.5em 0 -3em #fff,
    0 5em 0 -2.7em #fff, -3.5em 3.5em 0 -3em #fff, -5em 0 0 -2.7em #fff,
    -3.5em -3.5em 0 -3em #fff;
}
